import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
    static values = {
        language: String,
        cdata: String,
        redirect: String,
        retry: Number,
    }

    hue;
    complimentaryHue1;
    complimentaryHue2;
    instance;

    initialize() {
        this.hue               = 220;
        this.complimentaryHue1 = this.hue + 30;
        this.complimentaryHue2 = this.hue + 60;

        document.documentElement.style.setProperty('--hue', this.hue);

        document.documentElement.style.setProperty(
            '--hue-complimentary1',
            this.complimentaryHue1
        );

        document.documentElement.style.setProperty(
            '--hue-complimentary2',
            this.complimentaryHue2
        );

        if (null === window.turnstile || !window.turnstile) {
            const script = document.createElement('script');

            script.src   = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';
            script.async = true;
            script.defer = true;

            document.head.appendChild(script);
        }
    }

    connect() {
        let retries = 0;

        window.onloadTurnstileCallback = () => {
            this.instance = window.turnstile?.render(this.element, {
                // sitekey: '1x00000000000000000000AA', // Always passes (visible)
                // sitekey: '2x00000000000000000000AB', // Always blocks (visible)
                // sitekey: '1x00000000000000000000BB', // Always passes (invisible)
                // sitekey: '2x00000000000000000000BB', // Always blocks (invisible)
                // sitekey: '3x00000000000000000000FF', // Forces an interactive challenge (visible)
                sitekey: '0x4AAAAAAAMzzokzCHdiVkCN',
                theme: 'light', // [light|dark|auto]
                language: this.languageValue,
                'response-field': false,
                size: 'normal', // [normal|compact]
                appearance: 'always', // [always|execute|interaction-only]
                retry: 'never',
                action: 'firewall',
                cData: this.cdataValue,

                callback: async (token) => {
                    const { status } = await axios.post(location.href, { token });

                    if (200 === status) {
                        location.href = this.redirectValue;
                    }
                },

                'error-callback': () => {
                    if (++ retries >= this.retryValue) {
                        window.setTimeout(
                            () => {
                                window.turnstile.remove(this.instance);

                                const h1 = document.createElement('h1');
                                const h2 = document.createElement('h2');

                                h1.append('403');
                                h2.append('Access denied');

                                this.element.appendChild(h1);
                                this.element.appendChild(h2);
                            },
                            3000
                        );

                        return;
                    }

                    window.setTimeout(
                        () => window.turnstile.reset(this.instance),
                        Math.floor(Math.random() * 5000) + 3000
                    );
                },

                'unsupported-callback': () => {},
            });
        }
    }
}
