import { Application, defaultSchema } from '@hotwired/stimulus';

const app = Application.start(
    document.documentElement,
    {
        ...defaultSchema,
        controllerAttribute: 'data-plugin'
    }
);

app.debug = process.env.NODE_ENV === 'development';

export default app;
